import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import KukeFeature from "../components/kukeFeature"
import MenuItems from "../components/menuItems"
import kukeFeatures from "../json/kuke-features.json"

const FeaturePage = function () {
  let isMobile = false
  if (typeof window !== "undefined") {
    isMobile = document.documentElement.clientWidth <= 1140
  }

  return (
    <Layout cur="/features/" stickyHeader>
      <SEO
        title="功能介绍"
        keywords="酷课功能,酷课视频简介,"
        description="酷课约课系统帮助瑜伽馆、舞蹈工作室、少儿培训机构,高效地进行会员管理、课程/课表/预约管理、员工管理、在线营销、在线交易以及统计分析，智慧经营，提升运营效率，增强市场竞争力。"
      />

      <MenuItems
        list={kukeFeatures.map(v => ({ id: v.icon, name: v.title }))}
        title="酷课约课系统有哪些功能？"
        isMobile={isMobile}
        idPre="intro-"
      />
      <KukeFeature isMobile={isMobile} />
    </Layout>
  )
}

export default FeaturePage
