import React from "react"
// import classNames from 'classnames'
import st from "./css/MenuItems.module.scss"
import { CDNBASE } from "../utils/maps"
// import Svg from './svg'

const MenuItems = ({ list, title, isMobile, styles, idPre = "" }) => {
  return (
    <div className={st.wrapper}>
      <h2>{title}</h2>
      <div
        className={st.itemsWrapper}
        style={{
          width: !isMobile
            ? `${(list.length / 7) * 100 + list.length * 5}%`
            : "100%",
          ...styles,
        }}
      >
        {list.map(v => {
          return (
            <a key={v.id} href={`#${idPre}${v.id}`} className={st.item}>
              <div
                className={st.img}
                style={{
                  backgroundImage: `url(${CDNBASE}/home/kuke_features/icons/${v.id}.png)`,
                }}
              ></div>
              <div className={st.name}>{v.name}</div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

// MenuItems.propTypes = {
//   visible: PropTypes.bool,
//   onClose: PropTypes.func,
//   src: PropTypes.string,
// }

// MenuItems.defaultProps = {
//   visible: false,
//   onClose: () => { },
//   src: ``,
// }

export default MenuItems
